<template>
    <div>
        <div class="row">
            <div class="col-lg-12">

                <div class="card">
                    <div class="card-header tx-white">
                        Create Blacklist Entry
                    </div>
                    <div class="card-body">
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Registration <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <input type="text" required="" name="registration" v-model="blacklist.plate" class="form-control form-control-dark">
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-md-3 col-form-label">Reason <span class="text-danger">*</span></label>
                            <div class="col-md-9">
                                <select v-model="blacklist.reason" class="form-control form-control-dark select2" v-select2>
                                    <option v-for="r in reasons" v-bind:value="r">{{r}}</option>
                                </select>
                            </div>
                        </div>

                        <p class="text-white" v-if="submitting">{{submitStatus}}</p>
                        <p class="text-danger" v-if="error">{{errorMsg}}</p>

                        <submit-button @click="create" :background="'btn-success'" :text="'Finish and Create'" :clicked="submitting" />
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SubmitButton from "@/components/SubmitButton";
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
    name: "CreateBlacklist",
    components: {SubmitButton},
    data(){
        return {
            submitting: false,
            submitStatus: "",
            error: false,
            errorMsg: "",
            blacklist: {
                registration: "",
                reason: "",
            },
            reasons: [
                "Drive Off",
                "No Means of Payment",
                "Fly Tipping",
                "Aggressive",
                "Theft",
                "Plate Mismatch"
            ]
        }
    },
    methods: {
        create(){
            this.error = false;
            this.submitting = true;
            axios.put(`https://api.varsanpr.com/api/blacklist`, {
                reason: this.blacklist.reason,
                client_id: this.$store.state.auth.user.selectedClient,
                registration: this.blacklist.plate
            }, {
                headers: authHeader()
            })
                .then(response => {
                    this.$router.push(`/blacklist`);
                })
                .catch(error => {
                    if(error.response){
                        if(error.response.status == 422){
                            this.errorMsg = "";
                            for(let key in error.response.data.errors){
                                error.response.data.errors[key].forEach(msg => {
                                    this.errorMsg += `<br/>${msg}`;
                                })
                            }
                            this.error = true;
                        }
                        if(error.response.status == 401){
                            this.errorMsg = error.response.data.message;
                            this.error = true;
                        }
                    }
                });
        },
    },
}
</script>

<style scoped>

</style>
